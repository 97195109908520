import React from "react";
import { reducer } from "./reducer";

export const GlobalStateContext = React.createContext(undefined);
export const GlobalDispatchContext = React.createContext(undefined);

const GlobalContextProvider = ({ children }) => {
  const initialState = {
    filters: [],
  };
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export default GlobalContextProvider;
