export const FILTER_ACTION = "FILTER";
export const STATI = [0, 1, 2];
export const FILTER_NAMES = ["Geplant", "Laufend", "Fertiggestellt"];

export function reducer(state, action) {
  switch (action.type) {
    case FILTER_ACTION:
      return {
        ...state,
        filters: action.value,
      };
    default:
      throw new Error(action.type + ": Bad Action Type");
  }
}
